import * as React from "react";
import Slider from "react-slick";
import { useState } from "react";
import "../styles/main.scss";
import IndustriesCard from "../components/cards/industries-card";
import ServicesCard from "../components/cards/services.card";
import PrivacyPolicy from "../components/privacy-policy";
import { industriesCardsData } from "../content/cards-content/industries-cards";
import { servicesCardsData } from "../content/cards-content/services-cards";
import logo from "../assets/icons/icon.svg";

const IndexPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const getWindowWidth = () => {
    if (typeof window !== "undefined") {
      return window.innerWidth > 768 ? 3 : 1;
    }
    return 1;
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: getWindowWidth(),
    slidesToScroll: getWindowWidth(),
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header className="header">
        <div className="header__navigation">
          <div className="logo">
            <img src={logo} />
          </div>
          <nav className="nav">
            <input id="menu__toggle" type="checkbox" />
            <label class="menu__btn" for="menu__toggle">
              <span></span>
            </label>
            <ul className="nav__list header-nav__list">
              <li className="nav__item">
                <a className="nav__link" href="#services">
                  Services
                </a>
              </li>
              <li className="nav__item">
                <a className="nav__link" href="#how-we-work">
                  How we work
                </a>
              </li>
              <li className="nav__item">
                <a className="nav__link" href="#industries">
                  Industries
                </a>
              </li>
              <li className="nav__item">
                <a className="nav__link" href="#contacts">
                  Contacts
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="header__title">
          <h1 className="header__heading">Innovation for your success</h1>
          <h4 className="header__text">
            We help businesses navigate the ever-changing technological
            landscape and achieve their strategic objectives through innovative
            solutions and expert guidance.
          </h4>
        </div>
      </header>
      <main className="main">
        <section className="section-services common" id="services">
          <div className="section-services__content">
            <div className="common__header section-services__header-styles">
              <h2 className="common__heading"> Services</h2>
              <p
                className="common__text"
                style={{
                  color: "rgba(2, 5, 9, 0.8)",
                }}
              >
                We offer a wide range of services that cater to the individual
                needs of our clients. Our team delivers results-driven solutions
                that help businesses thrive.
              </p>
            </div>
            <div className="section-services-cards">
              {servicesCardsData.map(({ imgUrl, id, title, content }) => (
                <ServicesCard
                  imageUrl={imgUrl}
                  id={id}
                  title={title}
                  text={content}
                />
              ))}
            </div>
          </div>
        </section>
        <section className="section-industries common" id="industries">
          <div className="common__header section-industries__header-styles">
            <h2 className="common__heading section-industries__heading">
              Industries
            </h2>
            <p
              className="common__text"
              style={{
                color: "rgba(255, 255, 255, 0.8)",
              }}
            >
              Our team has a wealth of experience and expertise to tackle the
              most complex software development projects. We have a proven track
              record of success in a variety of industries, including:
            </p>
          </div>
          <Slider {...settings} className="section-industries__cards-list">
            {industriesCardsData.map(({ title, imgUrl, content, id }) => (
              <IndustriesCard
                imageUrl={imgUrl}
                title={title}
                text={content}
                id={id}
              />
            ))}
          </Slider>
        </section>
        <section className="section-how-we-work" id="how-we-work">
          <div className="section-how-we-work__content">
            <div className="common__header section-services__header-styles">
              <h2 className="common__heading">How we work</h2>
              <p
                className="common__text"
                style={{ color: "rgba(2, 5, 9, 0.8)" }}
              >
                We provide our clients with the highest level of flexibility and
                offer three engagement models to meet their unique project
                requirements:
              </p>
            </div>
            <div className="how-we-work-content">
              <div className="how-we-work-content__head">
                <div className="how-we-work-content__titles">
                  <p className="how-we-work-content__title">
                    Hire Experienced Developers
                  </p>
                  <p className="how-we-work-content__title">
                    Fixed Price Development
                  </p>
                  <p className="how-we-work-content__title">
                    Transparent Hourly Rate
                  </p>
                </div>
                <div className="section-how-we-work__image-container">
                  <img
                    className="section-how-we-work__image"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPFSURBVHgBxVfNj9tEFH/zZc9qjfDBUb3USEYsoig5LDeO9D/ihOBUc6v4p7ZHbgRplw91UX0wNJAccsiyjmfGw7yEtOluxutGQf1JucRv5n3M+/g9Aj3xZXHOL68HCeci5tRGGrRkhHH8ZqzRHHitW7LQWs2Hx9PZs+Kx7nMvuU8gL86lrj/MLGsSsG0NwswawuZDGNbPCqLXxllewjhSlkZaiVhQkhhDJ2H4Z1kWj+u9DECPr+pBbhikoWBVDo+qjcL7jX4hAepUKZs2pp0N5bT0RYT4vFbqwRlxh0/lsOyreJchy2WTU2piIf4a74rGHQMGxXkUqAcj4KL647tPKjgATp88z260yhrRXkyL0WL7G4U3LD6XqFyItjyUcsSVuwvvDBQdoY6dBuCbY9jR87IYTeDAwDuP3N2oA3XdMQATrm3Z/JCe3wZGAvPq0una/LeyZJ10NAlFML7vkvirH+NIvpdgWRJCVuEk1tW/6wF9yq5yFXGiBl84nRXKrpLw5NvfHllX45Onn5W+g5uyBM6yLgVLbaquskPkxS+5rg2vno6uKLiLGWtTKeWkS/nv6uTsPuWI0Mmg7PY730YJk8oymqAMHbj2Slo7K4uPvKFDzy2xEfQEym6/8x1gdCyvsbVT7O2tFnOf7Kpsenh+GxgJzBevgLiZoW4KbrBcN4uFT265/CCHPRFJkfi+ve/mCdAmcmWo5Vk89xqABsKewHf2ffsZprWAQFIcqV0Zy8j+BmzKdCeczpa0nMI7BkUy0VUy1toa9oSx2v+0WIKWahcBVw4w8IYKWyfsizbwGpBDHCloagquhQb/hN5y4VLtbUAYBqXvG7InNJAihwO+9GZrWXw+B9de4W3hznQ1N6qO4zBUczp1BBIIlV15gAPEuIEDPYHDCZmU7zs2N0tVksN0RrEc8J1/rVN/t3MyL8XLca9IOJmPxafjbhqXpkhasfxXZcjlcRUwmnVFAY3A6cW5/AEPb0dkVSlaVJzXY5TpUr4e/Ta9kx/ZNxenA/eD/xk4+rMtPa8aEb6zi0Ly8Ovnbz14+uLhk58yZMjb+fG6E7oQC3E0BqGyvLhI4cBY3alFhjq2n2gHLb+IkL2CcrT8+8PwQ/Qcle+i5Z7F5IVLlJszJKl9eJ4PGxqHUxE939UX/LuhO5z9d9gYMnGMbdLXEFSMZU2YyZiBSdd21WM5Xa9XyBuRul27ztk27QI5xGaMrzbnvwfSRCY+4izBxkZMMOOSdXbDXga89go34MtkuRTxmqS8uZ7jUINX6/lw1nef/Bd51iFA/J+6jgAAAABJRU5ErkJggg=="
                  />
                  <div className="section-how-we-work__image-block">
                    <img
                      className="section-how-we-work__image"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPFSURBVHgBxVfNj9tEFH/zZc9qjfDBUb3USEYsoig5LDeO9D/ihOBUc6v4p7ZHbgRplw91UX0wNJAccsiyjmfGw7yEtOluxutGQf1JucRv5n3M+/g9Aj3xZXHOL68HCeci5tRGGrRkhHH8ZqzRHHitW7LQWs2Hx9PZs+Kx7nMvuU8gL86lrj/MLGsSsG0NwswawuZDGNbPCqLXxllewjhSlkZaiVhQkhhDJ2H4Z1kWj+u9DECPr+pBbhikoWBVDo+qjcL7jX4hAepUKZs2pp0N5bT0RYT4vFbqwRlxh0/lsOyreJchy2WTU2piIf4a74rGHQMGxXkUqAcj4KL647tPKjgATp88z260yhrRXkyL0WL7G4U3LD6XqFyItjyUcsSVuwvvDBQdoY6dBuCbY9jR87IYTeDAwDuP3N2oA3XdMQATrm3Z/JCe3wZGAvPq0una/LeyZJ10NAlFML7vkvirH+NIvpdgWRJCVuEk1tW/6wF9yq5yFXGiBl84nRXKrpLw5NvfHllX45Onn5W+g5uyBM6yLgVLbaquskPkxS+5rg2vno6uKLiLGWtTKeWkS/nv6uTsPuWI0Mmg7PY730YJk8oymqAMHbj2Slo7K4uPvKFDzy2xEfQEym6/8x1gdCyvsbVT7O2tFnOf7Kpsenh+GxgJzBevgLiZoW4KbrBcN4uFT265/CCHPRFJkfi+ve/mCdAmcmWo5Vk89xqABsKewHf2ffsZprWAQFIcqV0Zy8j+BmzKdCeczpa0nMI7BkUy0VUy1toa9oSx2v+0WIKWahcBVw4w8IYKWyfsizbwGpBDHCloagquhQb/hN5y4VLtbUAYBqXvG7InNJAihwO+9GZrWXw+B9de4W3hznQ1N6qO4zBUczp1BBIIlV15gAPEuIEDPYHDCZmU7zs2N0tVksN0RrEc8J1/rVN/t3MyL8XLca9IOJmPxafjbhqXpkhasfxXZcjlcRUwmnVFAY3A6cW5/AEPb0dkVSlaVJzXY5TpUr4e/Ta9kx/ZNxenA/eD/xk4+rMtPa8aEb6zi0Ly8Ovnbz14+uLhk58yZMjb+fG6E7oQC3E0BqGyvLhI4cBY3alFhjq2n2gHLb+IkL2CcrT8+8PwQ/Qcle+i5Z7F5IVLlJszJKl9eJ4PGxqHUxE939UX/LuhO5z9d9gYMnGMbdLXEFSMZU2YyZiBSdd21WM5Xa9XyBuRul27ztk27QI5xGaMrzbnvwfSRCY+4izBxkZMMOOSdXbDXga89go34MtkuRTxmqS8uZ7jUINX6/lw1nef/Bd51iFA/J+6jgAAAABJRU5ErkJggg=="
                    />
                  </div>
                  <img
                    className="section-how-we-work__image"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPFSURBVHgBxVfNj9tEFH/zZc9qjfDBUb3USEYsoig5LDeO9D/ihOBUc6v4p7ZHbgRplw91UX0wNJAccsiyjmfGw7yEtOluxutGQf1JucRv5n3M+/g9Aj3xZXHOL68HCeci5tRGGrRkhHH8ZqzRHHitW7LQWs2Hx9PZs+Kx7nMvuU8gL86lrj/MLGsSsG0NwswawuZDGNbPCqLXxllewjhSlkZaiVhQkhhDJ2H4Z1kWj+u9DECPr+pBbhikoWBVDo+qjcL7jX4hAepUKZs2pp0N5bT0RYT4vFbqwRlxh0/lsOyreJchy2WTU2piIf4a74rGHQMGxXkUqAcj4KL647tPKjgATp88z260yhrRXkyL0WL7G4U3LD6XqFyItjyUcsSVuwvvDBQdoY6dBuCbY9jR87IYTeDAwDuP3N2oA3XdMQATrm3Z/JCe3wZGAvPq0una/LeyZJ10NAlFML7vkvirH+NIvpdgWRJCVuEk1tW/6wF9yq5yFXGiBl84nRXKrpLw5NvfHllX45Onn5W+g5uyBM6yLgVLbaquskPkxS+5rg2vno6uKLiLGWtTKeWkS/nv6uTsPuWI0Mmg7PY730YJk8oymqAMHbj2Slo7K4uPvKFDzy2xEfQEym6/8x1gdCyvsbVT7O2tFnOf7Kpsenh+GxgJzBevgLiZoW4KbrBcN4uFT265/CCHPRFJkfi+ve/mCdAmcmWo5Vk89xqABsKewHf2ffsZprWAQFIcqV0Zy8j+BmzKdCeczpa0nMI7BkUy0VUy1toa9oSx2v+0WIKWahcBVw4w8IYKWyfsizbwGpBDHCloagquhQb/hN5y4VLtbUAYBqXvG7InNJAihwO+9GZrWXw+B9de4W3hznQ1N6qO4zBUczp1BBIIlV15gAPEuIEDPYHDCZmU7zs2N0tVksN0RrEc8J1/rVN/t3MyL8XLca9IOJmPxafjbhqXpkhasfxXZcjlcRUwmnVFAY3A6cW5/AEPb0dkVSlaVJzXY5TpUr4e/Ta9kx/ZNxenA/eD/xk4+rMtPa8aEb6zi0Ly8Ovnbz14+uLhk58yZMjb+fG6E7oQC3E0BqGyvLhI4cBY3alFhjq2n2gHLb+IkL2CcrT8+8PwQ/Qcle+i5Z7F5IVLlJszJKl9eJ4PGxqHUxE939UX/LuhO5z9d9gYMnGMbdLXEFSMZU2YyZiBSdd21WM5Xa9XyBuRul27ztk27QI5xGaMrzbnvwfSRCY+4izBxkZMMOOSdXbDXga89go34MtkuRTxmqS8uZ7jUINX6/lw1nef/Bd51iFA/J+6jgAAAABJRU5ErkJggg=="
                  />
                </div>
              </div>
              <div className="how-we-work-content__cards">
                <div className="how-we-work-content__card">
                  <h4 className="how-we-work-content__heading">
                    Dedicated Team
                  </h4>
                  <p className="how-we-work-content__text">
                    Automate finances and enhance security by creating payment
                    gateways, banking apps, investment platforms, and insurance
                    management systems.
                  </p>
                </div>
                <div className="how-we-work-content__card">
                  <h4 className="how-we-work-content__heading">Fixed Price</h4>
                  <p className="how-we-work-content__text">
                    Get software development at a fixed price, ensuring
                    predictability and cost control. No surprises, just
                    reliable, on-time delivery of your project within your
                    budget.
                  </p>
                </div>
                <div className="how-we-work-content__card">
                  <h4 className="how-we-work-content__heading">
                    Time & Material
                  </h4>
                  <p className="how-we-work-content__text">
                    Get in touch with us to learn more about our services and
                    how we can help your business succeed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-contacts" id="contacts">
          <h2 className="section-contacts__heading">Contacts</h2>
          <p className="section-contacts__text">
            Get in touch with us to learn more about our services and how we can
            help your business succeed:{" "}
            <a href="mailto: info@panecs.com">info@panecs.com</a>.
          </p>
        </section>
      </main>
      <footer className="footer">
        <div className="footer__block">
          <div className="logo">
            <img src={logo} />
          </div>
          <div className="footer__nav">
            <nav className="nav">
              <ul className="nav__list">
                <li className="nav__item">
                  <a className="nav__link" href="#services">
                    Services
                  </a>
                </li>
                <li className="nav__item">
                  <a className="nav__link" href="#how-we-work">
                    How we work
                  </a>
                </li>
                <li className="nav__item">
                  <a className="nav__link" href="#industries">
                    Industries
                  </a>
                </li>
                <li className="nav__item">
                  <a className="nav__link" href="#contacts">
                    Contacts
                  </a>
                </li>
              </ul>
            </nav>
            <p className="footer__address">
              Strovolou, 77 Strovolos Center, Floor 2, Flat 204,2018, Strovolos,
              Nicosia, Cyprus
            </p>
          </div>
        </div>
        <div className="footer__white-line"></div>
        <div className="footer__privacy-and-text">
          <p className="footer__text">© 2023. All rights reserved.</p>
          <div class="popover__wrapper">
            <a class="popover__link" onClick={handleClick}>
              <p
                class={
                  isOpen
                    ? "popover__title-active popover__title"
                    : "popover__title"
                }
              >
                Privacy
              </p>
            </a>
            {isOpen && (
              <div class="popover__content">
                <div class="popover__message">
                  <PrivacyPolicy />
                </div>
              </div>
            )}
          </div>
        </div>
      </footer>
    </>
  );
};

export default IndexPage;

export const Head = () => (
  <>
    <title>Panecs: Your Software Development</title>
    <link
      rel="icon"
      href="https://web.archive.org/web/20180829182318im_/https://cdn.ucraft.me/userFiles/panecs/images/favicon.png?v=1522164246"
      type="image/x-icon"
    ></link>
  </>
);
