import * as React from "react";
import "../../styles/main.scss";

const IndustriesCard = ({ imageUrl, title, text, id }) => {
  return (
    <>
      <div className="section-industries__card" key={id}>
        <div className="section-industries__img-container">
          <img src={imageUrl} className="section-industries__image" />
        </div>
        <div>
          <h4 className="section-industries-card__heading common-card__heading">
            {title}
          </h4>
          <p
            className="section-industries-card__text common-card__text"
            style={{ color: "rgba(255, 255, 255, 0.8)" }}
          >
            {text}
          </p>
        </div>
      </div>
    </>
  );
};

export default IndustriesCard;
