import * as React from "react";
import "../styles/main.scss";

const PrivacyPolicy = () => {
  return (
    <>
      <h1>Privacy Policy</h1>
      <p>
        We are committed to protecting and respecting your privacy. This policy
        provides you with the details of how any personal data we collect from
        you or that you provide us will be used and processed.
      </p>
      <ol>
        <li>
          <h3>Consent</h3>
          <p>
            Panecs privacy policy (”Privacy Policy”) describes how we (“we” or
            “us”) collect and use information in connection with your access to
            and use of our website and any of its subdomains, mobile
            applications, and related services (collectively, the ”Services”).
            <br />
            By accessing and/or using any of our Services, you accept and
            signify your informed consent to the practices described in this
            Privacy Policy.
          </p>
        </li>
        <li>
          <h3>Who We Are</h3>
          <p>
            We are Panecs. We are a global technology company headquartered in
            Cyprus.
            <br />
            For the purposes of the Data Protection Act (”DPA”) and in line with
            the EU General Data Protection Regulation (the ”GDPR”), Panecs is
            the ”Data Controller” and is responsible for your personal data.
            <br />
            If you have any concerns about the way we use your information or
            any questions about this Privacy Policy, please let us know. We can
            be contacted via email at{" "}
            <a className="privacy-policy__link" href="mailto: info@panecs.com">info@panecs.com</a> or you can
            write to us at Panecs, Strovolou, 77, Strovolos Center, Floor 2,
            Flat 204, 2018, Strovolos, Nicosia, Cyprus.
          </p>
        </li>
        <li>
          <h3>Information we collect from you</h3>
          <p>
            We may collect and process the following aspects of data about you:
            <br />
            Information that you provide us – this is information you give us by
            filling in forms on our website or by corresponding with us by
            phone, email, or otherwise or by applying for jobs via our website.
            <br />
            Information that we collect from you – whenever you visit our
            website, we will automatically collect technical information and
            information about your visit.
            <br />
            Information we receive from other sources – We may receive personal
            data about you from public sources such as Companies House and
            various third parties including for example business partners,
            sub-contractors in technical and payment services and analytics
            providers etc.
            <br />
            We may also collect, store and process information pertaining to
            visitors and users of our customer’s websites or services, solely
            for and on our customer's behalf. For such purposes, Panecs serves
            and shall be considered as a “Data Processor” and our customer shall
            be the “Controller” (as both such capitalized terms are defined in
            the European Data Protection Directive) of such information and the
            responsibility for the security and authorized usage of that
            information lies with our customer. If you are a visitor or user of
            any of our customer’s websites or services and have any requests or
            queries regarding your personal information, please contact them
            directly.
          </p>
        </li>
        <li>
          <h3> Do we collect information from minors?</h3>
          <p>
            We do not knowingly collect personal information from individuals
            who are under 18. If we learn that we have collected personal
            information on users under the age of 13, we will promptly delete
            that data from our systems.
          </p>
        </li>
        <li>
          <h3>What we do with your information</h3>
          <ul>
            <li>
              To ensure that content from our website is presented in the most
              effective way for you and your computer;
            </li>
            <li>
              To carry out any requests or obligations arising from any contact
              you may have with us;
            </li>
            <li>
              To provide you with information or services that you have
              requested from us or which we feel may interest you, if you have
              given us consent to get in contact with you;
            </li>
            <li>
              To enable you to participate in any interactive features our
              service offers, as and when you choose to do so;
            </li>
            <li>To consider any job applicants;</li>
            <li>To notify you of any changes to our service.</li>
          </ul>
        </li>
        <li>
          <h3>Technical Information</h3>
          <p>
            We may collect information about your computer, including where
            available your IP address, operating system and browser type for
            system administration. This is only statistical data and does not
            identify any individual.
            <br />
            We also collect details of your visits to our website in the form of
            analytics, this could include places and resources you access on our
            site, time spent on our site and where you are directed to our site
            from. Please see section 12 for more information.
          </p>
        </li>
        <li>
          <h3>Where we hold your information</h3>
          <p>
            Our website is hosted by Amazon Web Services ("AWS") and we hold
            your data with AWS and Google which are contracted by use for the
            provision of technical services. We remain responsible at all times
            for the security of your information, but if you want to know more
            about how we interact with Amazon you can view their privacy notice
            <a className="privacy-policy__link" href="https://aws.amazon.com/ru/privacy/">here</a>. Google
            provides a lot of information about how it handles personal data and
            the best place to start is{" "}
            <a className="privacy-policy__link" href="https://policies.google.com/privacy">here</a>.
          </p>
        </li>
        <li>
          <h3>Security of your personal data</h3>
          <p>
            We take the security of your personal data very seriously and have
            policies and procedures in place to ensure our compliance with the
            DPA and GDPR.
            <br />
            We limit access to your personal data to those employees, agents,
            contractors and other third parties who have a business need to
            know. They will only process your personal data on our instructions
            and they are subject to a duty of confidentiality.
            <br />
            We have put in place procedures to deal with any suspected personal
            data breach and will notify you and any applicable regulator of a
            breach where we are legally required to do so.
            <br />
            Although we do our very best to protect your personal details,
            transmission of information via the internet is not completely
            secure and we can not and do not guarantee the absolute security and
            protection of your data. Online information is encrypted and
            transmitted securely. You can verify this by looking for a closed
            lock icon at the bottom of your web browser or an "https" at the
            beginning of the address of the web page.
            <br />
            Any transmission is at the risk of the site user. Once we have
            received any information we will use strict procedures and security
            features to try and prevent unauthorized access.
          </p>
        </li>
        <li>
          <h3>Disclosure of your information</h3>
          <p>
            We may disclose your personal information to third parties if we are
            under a duty to disclose or share your data in order to comply with
            any legal obligation.
            <br />
            We may share your data with our partner companies, which we are
            working for outsourcing some of the work to. We are responsible for
            ensuring that our partner companies have an ”adequate level of
            protection” under the GDPR and have put in place a strict data
            protection policy and contractual clauses to protect any personal
            data we share with them.
            <br />
            We use third party selected service providers including IT and
            system management services, legal and financial advisors, billing
            and payment processing services, fraud detection and prevention
            services, web analytics, marketing services, recruitment services,
            content providers and HM Revenue & Customs, regulators and other
            authorities (collectively, “Third Party Service(s)”)
            <br />
            We may also disclose your information to third parties to whom we
            may choose to sell, transfer, or merge parts of our business or our
            assets. Alternatively, we may seek to acquire other businesses or
            merge with them. If a change happens to our business, then the new
            owners may use your personal data in the same way as set out in this
            Privacy Policy.
            <br />
            We require all Third Party Services to respect the security of your
            personal data and to treat it in accordance with the law. We do not
            allow our Third Party Services to use your personal data for their
            own purposes and only permit them to process your personal data for
            specified purposes and in accordance with our instructions.
          </p>
        </li>
        <li>
          <h3>Third Party links</h3>
          <p>
            Our website may from time to time contain links to and from other
            websites. If you were to follow a link to any of these sites please
            note that these websites have their own privacy policies and we do
            accept any responsibility or liability for these policies. Please
            make sure to check these policies before you submit any personal
            data to any of these websites.
          </p>
        </li>
        <li>
          <h3>Your rights</h3>
          <p>
            You have the right to find out about what information we hold about
            you. If you wish to access and/or request us to make corrections to
            the personal information that you have stored with us, please
            contact us via email:{" "}
            <a className="privacy-policy__link" href="mailto: info@panecs.com">info@panecs.com</a>.
            <br />
            You also have the right ”to be forgotten” and to have your
            information permanently deleted from our systems. Again if you would
            like to exercise this right then contact us at the email address
            above.
            <br />
            There will be no charge for reasonable electronic access to your
            information or for your right to be forgotten.
            <br />
            In some regions (such as EEA and UK) you have certain rights under
            applicable data protection laws. These may include the right to
            request access and obtain a copy of your personal information, to
            request rectification or erasure, and to restrict the processing of
            your personal information. To make such a request, please contact us
            via email.
          </p>
        </li>
        <li>
          <h3> How long we hold your information for</h3>
          <p>
            We will only retain your personal data for as long as necessary to
            fulfill the purposes we collected it for, including for the purposes
            of satisfying any legal, accounting, or reporting requirements.
            <br />
            To determine the appropriate retention period for personal data, we
            consider the amount, nature, and sensitivity of the personal data,
            the potential risk of harm from unauthorized use or disclosure of
            your personal data, the purposes for which we process your personal
            data and whether we can achieve those purposes through other means,
            and the applicable legal requirements.
          </p>
        </li>
        <li>
          <h3>
            Changes to Privacy Policy and your duty to inform us of changes
          </h3>
          <p>
            Any changes we make to our Privacy Policy will be displayed on this
            page or otherwise communicated to users.
            <br />
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal data changes
            during your relationship with us.
          </p>
        </li>
      </ol>
    </>
  );
};

export default PrivacyPolicy;
