import * as React from "react";
import "../../styles/main.scss";

const ServicesCard = ({ imageUrl, title, text, id }) => {
  return (
    <>
      <div className="section-services-card common-card" key={id}>
        <div className="section-services-card__image-wrapper">
          <img
            className="section-services-card__image"
            src={imageUrl}
          />
        </div>
        <h4 className="section-services-card__heading common-card__heading">
          {title}
        </h4>
        <p className="section-services-card__text common-card__text">
          {text}
        </p>
      </div>
    </>
  );
};

export default ServicesCard;
